<template>
  <v-app id="app">
  <router-view></router-view>
  </v-app>
</template>

<style lang="scss">
@import "./styles/main.scss";
</style>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>


<script>

export default {
  props: {
  }

};
</script>
