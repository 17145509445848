import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import api from "./modules/api";


Vue.config.productionTip = false

Vue.prototype.$backendUrl = window.VUE_APP_MAIN_BACKEND_URL
api.backendUrl = window.VUE_APP_MAIN_BACKEND_URL


    new Vue({
      el: '#app',
      render: h => h(App),
      vuetify,
      router
    })

