const api = {
    backendUrl: null,
    token: null,

    async get(path, querySpec, mode = "json") {
        if (path[0] !== '/') {
            path = '/' + path
        }
        const url = new URL(this.backendUrl + path)
        if (querySpec != null) {
            url.search = new URLSearchParams(this.clean(querySpec)).toString()
        }
        try {
            let req = await fetch(url.toString())
            if (req.ok) {
                let payload
                if (mode === "json") {
                    payload = await this.tryGetJson(req)
                } else if (mode === "blob") {
                    payload = await req.blob()
                }
                return {ok: true, payload: payload}
            } else {
                console.log(req)
                return {ok: false, payload: null, error: req.err}
            }
        } catch (e) {
            console.log(e)
            return {ok: false, error: e}
        }
    },

    async tryGetJson(req) {
        let result = null
        try {
            result = await req.json()
        } catch (e) {
            result = null
        }
        return result
    },

    clean (obj) {
        for (let propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName]
            }
        }
        return obj
    }

}

export default api
